<template>
  <div class="newslist">
    <Header/>
    <div class="topbox">
      <img src="../../../assets/topbannerimg.png" alt="">
    </div>
    <div class="content">
      <div class="main w">
        <div class="title">
          {{formdata.title}}
        </div>
        <div class="timebox">
          <div class="middlebox">
            <div class="boxitem">
              <img class="boximg" width="12px" height="12px" src="../../../assets/icon-time.png" alt="">
              <span class="boxtext">日期：{{formdata.time}}</span>
            </div>
            <div class="boxitem">
              <img class="boximg" width="12px" height="12px" src="../../../assets/icon-feiji.png" alt="">
              <span class="boxtext">来源：{{formdata.form}}</span>
            </div>
            <div class="boxitem">
              <img class="boximg" width="14px" height="10px" src="../../../assets/icon-eye.png" alt="">
              <span class="boxtext">浏览次数：{{formdata.num}}</span>
            </div>
          </div>
          <div class="rightbox">
            字号：
            <span @click="changesize(0)" :class="state==0?'select':''">小</span>
            <span @click="changesize(1)" :class="state==1?'select':''">中</span>
            <span @click="changesize(2)" :class="state==2?'select':''">大</span>
          </div>
        </div>
        <div class="wenben" :class="{'sizeone':state==0,'sizetwo':state==1,'sizethree':state==2}" v-html="formdata.text">
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  data () {
    return {
      state: 1,
      formdata: {
        text: '<p><span style="color: rgb(97, 104, 124);">美团的使命是“帮大家吃得更好，生活更好”。作为一家生活服务电子商务平台，公司聚焦“Food +Platform”战略，以“吃”为核心，通过科技创新，和广大商户与各类合作伙伴一起，努力为消费者提供品质生活，推动生活服务业需求侧和供给侧数字化升级。</span></p ><p><span style="color: rgb(97, 104, 124);">2018年9月20日，美团正式在港交所挂牌上市。美团将始终坚持以客户为中心，不断加大在科技研发方面的投入，更好承担社会责任，更多创造社会价值，与广大合作伙伴一起发展共赢。</span></p ><p><span style="color: rgb(97, 104, 124);">Introduction to Meituan</span></p ><p><span style="color: rgb(97, 104, 124);">The mission of Meituan is “We help people eat better, live better ”. As China’s e-commerce platform for services, Meituan places its focus on the “Food+ Platform” strategy and always takes “eating” as the core of its businesses. Resorting to its advantages in scientific innovation, Meituan joins hands with a vast number of merchants and diverse partners to offer consumers quality services and accelerate the digital upgrade of the life service industry on both demand and supply sides.</span></p ><p><span style="color: rgb(97, 104, 124);">Officially listed on the Main Board of the Stock Exchange of Hong Kong Limited on September 20, 2018, Meituan has always centered on customers and kept increasing investment in scientific R&amp;D, thus better fulfilling its social responsibilities, creating more values for the society, and seeking win-win cooperation with all partners.</span></p >',
        title: '关于做好学生离校后就业工作的通知',
        time: '2022-04-15',
        form: '就业办',
        num: '100'
      }
    }
  },
  methods: {
    changesize (val) {
      this.state = val
    }
  },
  components: {
    Footer, Header
  }
}
</script>

<style scoped lang="less">
.newslist{
  min-width: 1200px;
  .topbox{
    height: 420px;
    width: 100%;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .content{
    width: 100%;
    min-height: 600px;
    background-color: #F7FAFF;
    background-image: url(../../../assets/listbgimg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .main{
      .title{
        text-align: center;
        padding-top: 32px;
        color: #303236;
        font-size: 32px;
        font-weight: bold;
      }
      .timebox{
        height: 60px;
        line-height: 60px;
        background-color:rgba(0, 77, 155, 0.04);
        margin-top: 24px;
        border-radius: 8px;
        position: relative;
        .middlebox{
          display: flex;
          justify-content: center;
          .boxitem{
            display: flex;
            align-items: center;
            margin-right: 26px;
            .boximg{
              margin-right: 8px;
            }
            .boxtext{
              color: #666;
              font-size: 12px;
            }
          }
        }
        .rightbox{
          position: absolute;
          right: 20px;
          top: 0;
          color: #666666;
          font-size: 12px;
          .select{
            font-size: 14px;
            color: #004D9B;
            font-weight: bold;
          }
          span{
            margin-right: 15px;
            cursor: pointer;
          }
        }
      }
      .wenben{
        color: #666;
        margin-top: 25px;
        line-height: 25px;
      }
      .sizeone{
        font-size: 12px;
      }
      .sizetwo{
        font-size: 14px;
      }
      .sizethree{
        font-size: 16px;
      }
    }
  }
}
</style>
